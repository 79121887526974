@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap);
body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  color: rgb(43, 115, 174);
  background-color: rgb(180, 179, 179);
  /* background-image:
    linear-gradient(rgba(100, 65, 50, 0.7), rgba(100, 65, 50, .7)),
    url('../assets/imgs/recorders1.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover; */
}

iframe {
  max-width: 100%;
}

.content {
  padding: 3%;
  min-height: 90vh;
}

h3 {
  color: rgb(5, 40, 78);
  font-weight: 300;
}
h4 {
  color: rgb(5, 40, 78);
  font-weight: 300;
}

.dark-header {
  color: rgb(173, 78, 6);
}

.btn-info {
  background-color: steelblue;
  color: whitesmoke;
}

.btn-info:hover {
  background-color: whitesmoke;
  color: steelblue;
  outline: none;
}

.button-wrapper {
  height: 100px;
  padding: 4% 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons-wrapper {
  background-color: rgba(173, 78, 6, 0.6);
  color: rgb(235, 210, 192);
  padding: 1%;
}

.contact-middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.851);
  padding: 20px;
}

.agent-container {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 20px 0;
}

.text a {
  color: rgb(19, 66, 104);
}

.contact-middle a {
  color: rgb(43, 115, 174);
  align-self: center;
  justify-self: center;
}

.contact-middle-photo img {
  width: 100%;
}

.contact-newsletter-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
  color: rgb(235, 210, 192);
}

.flag:hover {
  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;

  width: 80%;
  max-width: 700px;
  margin: 25px 0;
  background-color: white;
  padding: 20px;
}

.form input {
  margin-bottom: 20px;
  outline: none;
}

.label-name {
  border: 0.5px solid rgb(173, 78, 6);
  padding: 3px;
  color: rgb(173, 78, 6);
}

.label-name:hover {
  text-decoration: none;
  color: rgb(163, 114, 76);
}

.light-header {
  color: rgb(235, 210, 192);
}

.group-biog ul {
  list-style: none;
}

.group-biog {
  text-align: justify;
}

@media (min-width: 800px) {
  .group-biog-2-columns,
  .biog-page {
    -webkit-columns: 2;
            columns: 2;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
    -moz-columns: 2;
  }
}

.biog-page img {
  margin-bottom: 15px;
}

.biog-page-portrait {
  width: 100%;
}

.middle {
  padding: 5%;
  background-color: rgba(255, 255, 255, 0.851);
}
.photo-container {
  display: flex;
  flex-direction: column;
  color: white;
}

.photo-container img {
  max-width: 100%;
  height: 300px;
}

.post-list-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.privacy-link {
  margin-bottom: 25px;
  color: darkgreen;
}

.privacy-button {
  margin-left: 20px;
}

.close-cross:hover {
  cursor: pointer;
  text-decoration: underline !important;
}

.privacy-link:hover {
  cursor: pointer;
}

.privacy-text {
  text-align: justify;
  margin-top: 25px;
}

.privacy-text a {
  color: black;
}

.recording-images {
  display: flex;
  margin-top: 10px;
  margin-left: 5px;
}
.recording-images a {
  margin-right: 5px;
}

.small-button {
  border: 0.5px solid rgb(119, 54, 4);
  border-radius: 15px;
  padding: 10px;
  color: rgb(173, 78, 6);
  margin: 4% 0 0 0;
}

.xsmall-button {
  border-radius: 15px;
  padding: 5px;
  color: rgb(173, 78, 6) !important;
  background-color: rgb(240, 226, 206);
  margin-bottom: 4%;
}

.small-button:hover,
.xsmall-button:hover {
  background-color: rgb(163, 114, 76);
  color: rgb(119, 54, 4);
  text-decoration: none;
  transition: 500ms;
  cursor: pointer;
  border: none;
}

.big-button {
  color: rgb(119, 54, 4);
  padding: 2%;
  background-color: rgb(119, 54, 4);
  border-radius: 10px;
  background-color: rgb(240, 226, 206);
}

.big-button:hover {
  background-color: rgb(163, 114, 76);
  color: rgb(119, 54, 4);
  text-decoration: none;
  transition: 500ms;
  cursor: pointer;
  border: none;
}

.social {
  margin-bottom: 20px;
}

.title-bar {
  margin-bottom: 20px;
  color: steelblue;
}

.sharing-buttons {
  text-align: right;
}
/* 
  .homepage-video{
  min-height: 50vh;
  } */

#itunes {
  color: steelblue;
}

#itunes:hover {
  color: rgb(98, 179, 245);
}

#spotify {
  color: green;
}

#spotify:hover {
  color: rgb(5, 216, 5);
}

/* LIGHTBOX ETC */
/* image grid styles */
.img-grid {
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
}
.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
.img-wrap img {
  min-width: 50%;
  min-height: 50%;
  max-width: 150%;
  position: absolute;
  top: 0;
  right: 0;
}

/* modal styles */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 8);
}
.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto 20px;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

.backdrop h5 {
  text-align: center;
  color: white;
}

.blog-item-image {
  width: 100%;
  margin-bottom: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
}

.event {
  background-color: rgba(255, 255, 255, 0.6);
  color: rgb(173, 78, 6);
  margin-bottom: 2%;
  margin-top: 2%;
  padding: 5%;
  min-height: 350px;
}

.recording {
  background-color: rgba(255, 255, 255, 0.6);
  color: rgb(173, 78, 6);
  margin-bottom: 5%;
  padding: 1%;
}

.recording a {
  color: rgb(119, 54, 4);
}

.project {
  background-color: rgba(255, 255, 255, 0.6);
  color: rgb(173, 78, 6);
  margin-bottom: 5%;
  padding: 1%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project img {
  height: 40vh;
  max-width: auto;
}

.project a {
  color: rgb(119, 54, 4);
}

.news-item {
  background-color: rgba(255, 255, 255, 0.851);
  color: rgb(43, 115, 174);
  margin-bottom: 5%;
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.news-item h5 {
  color: rgb(5, 40, 78);
  font-weight: 300;
}

/* .ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
} */

a {
  color: rgb(19, 66, 104);
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: rgb(235, 210, 192);
}

.quote {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 5%;
  padding-bottom: 5%;
}

.title {
  font-weight: 100;
  padding-top: 5%;
  padding-left: 5%;
}


.video{
    background-color: rgba(255, 255, 255, .8);
    color: rgb(173, 78, 6);;
    /* margin-bottom: 2%; */
    padding: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
.toolbar {
  position: fixed;
  background-color: steelblue;
  width: 100%;
  height: 56px;
  top: 0;
  left: 0;
  z-index: 200;
}

.toolbar img {
  height: 56px;
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

.toolbar__logo {
  margin-left: 1rem;
}
.toolbar__logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.toolbar__logo__mechelen {
  padding: 10px;
}

.spacer {
  flex: 1 1;
}
.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
}

.toolbar_navigation-items a {
  color: white;
  text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: rgb(185, 205, 207);
}

.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
  }
  
  .toggle-button:focus {
    outline: none;
  }
  
  .toggle-button__line {
    width: 30px;
    height: 2px;
    background: white;
  }

  .toggle-button:hover{
    transform: rotate(90deg);
    transition: 500ms;
  }
.side-drawer {
  height: 100%;
  /* background-image:
    linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)),
    url('../../assets/imgs/recorders2.jpg');
    background-position: center;
    background-size: cover;     */
  background-color: steelblue;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 300px;
  z-index: 200;
  transform: translateX(-100%);
  transition: 300ms ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer li {
  margin-bottom: 4%;
}

.side-drawer ul {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  list-style: none;
}

.drop-down {
  color: whitesmoke;
}

.side-drawer a {
  color: whitesmoke;
  text-decoration: none;
}

.dropdown-item a {
  color: whitesmoke;
}
.side-drawer a:hover,
.side-drawer a:active {
  color: goldenrod;
}

.header {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(235, 210, 192);
}

.mechelen-logo {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  width: 70%;
  margin: auto;
}

.social {
  color: rgb(235, 210, 192);
  display: flex;
  justify-content: space-around;
}

.i18n {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.backdrop{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0,0,0,.3);
    z-index: 100;
    top: 0;
    left: 0;
}
